import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let NoPermission = class NoPermission extends Vue {
};
NoPermission = __decorate([
    Component({
        name: 'no-permission',
    })
], NoPermission);
export default NoPermission;
